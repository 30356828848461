import {Branding} from '../Branding.types'

import LogoPNQ from './icons/Logo-PNQ-white.svg'
import LogoPNQOnWhite from './icons/Logo-PNQ.svg'

export const hubPnqComAu: Branding = {
  nationalBrandName: 'Pioneer North Queensland Pty Ltd',
  nationalLogoUrl: LogoPNQ,
  nationalLogoTitle: 'PNQ',
  nationalUrl: 'https://hub.pnq.com.au',
  onWhiteLogoUrl: LogoPNQOnWhite,
  contactEmail: 'digital@pnq.com.au',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'PNQ',
  country: 'AU',
  termsAndConditionsOfSale:
    'https://www.hanson.com.au/media/6139/hanson-terms-and-conditions-of-sale.pdf'
}
